import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const GlobalStyles = css`
  body {
    align-items: flex-end;
    background: #d2cec6;
    box-sizing: border-box;
    color: #0f1512;
    display: flex;
    font-family: Dosis;
    font-weight: 200;
    justify-content: flex-start;
    margin: 0;
    min-height: 88vh;
    min-width: 100vw;
    padding: 0;
    transition-duration: 0.15s;
    transition-property: background-color, color;

    @media (prefers-color-scheme: dark) {
      background: #0d1713;
      color: #dad1be;
    }
  }
`;

export const StyledMain = styled.main`
  padding: 1.5em;
`;

export const StyledH1 = styled.h1`
  font-size: 13vw;
  font-weight: 200;
  line-height: 0.72em;
  margin: 0;
  
  .hyphen {
    display: inline-block;
    padding: 0 0.021em 0 0.035em;
    position: relative;
  }

  .y {
    display: inline-block;
    padding: 0 0.0165em;
    position: relative;
  }

  .i {
    clip-path: inset(0.5em 0 0);
  }

  .t {
    clip-path: inset(0.555em 0 0);
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 2.35em 1.5em;
  @media screen and (max-width: 1200px) {
    padding: 0 1.5em 1.5em;
  }
`;

export const StyledH2 = styled.h2`
  font-size: 1.5em;
  font-weight: 200;
  margin: 0;
  a {
    color: inherit;
  }
`;

export const SocialList = styled.ul`
  display: flex;
  flex-direction: row;
  font-size: 1em;
  gap: 0.5em;
  list-style: none;
  margin: 0;
  padding: 1em 0 0;
`;

export const SocialListItem = styled.li`
  display: block;
`;

export const SocialLink = styled.a`
  color: inherit;
`;
